import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';

import colors from '@utils/colors';
import { Caption } from '@components/type';

const Overlay = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 16px;
  color: ${colors.N0};
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms linear;
`;

const Wrapper = styled.div`
  position: relative;

  &:hover ${Overlay} {
    opacity: 1;
  }
`;

const imgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
};

const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
};

const MediaGalleryImage = ({
  onClick,
  photo,
  margin,
  title,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      style={{ margin, height: photo.height, width: photo.width, ...cont }}
    >
      <Overlay onClick={onClick}>
        <Caption>{title}</Caption>
      </Overlay>
      <Image
        alt={photo.alt || "Miss Hall’s School"}
        imgStyle={{ ...imgStyle }}
        fixed={photo}
      />
    </Wrapper>
  );
};

export default MediaGalleryImage;
