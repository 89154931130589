import React, { useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import Gallery from 'react-photo-gallery';
import styled from 'styled-components';

import { breakpoints, mq } from '@utils/responsive';
import Lightbox from '@components/Lightbox';
import Image from './Image';

const GalleryWrapper = styled.div`
  margin: 0 -8px;

  ${mq.gtmd`
    margin: 0 -16px;
  `}
`;

const LightboxWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const variants = {
  enter: {
    y: '100%',
    opacity: 0,
  },
  center: {
    zIndex: 9999,
    y: 0,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    y: '100%',
    opacity: 0,
  },
};

function MediaGallery({ items }) {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.tablet - 1 });

  const [[currentImage, direction], setCurrentImage] = useState([0, 0]);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const handleNext = () =>
    setCurrentImage([(currentImage + 1) % items.length, 1]);

  const handlePrev = () => {
    if (currentImage === 0) {
      setCurrentImage([items.length - 1, -1]);
    } else {
      setCurrentImage([(currentImage - 1) % items.length, -1]);
    }
  };

  const openLightbox = useCallback((index) => {
    setCurrentImage([index, 0]);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage([0, 0]);
    setViewerIsOpen(false);
  };

  const imageRenderer = (props) => (
    <Image
      {...props}
      onClick={() => openLightbox(props.index)}
    />
  );
  return (
    <>
      <GalleryWrapper>
        <Gallery
          margin={isMobile ? 8 : 16}
          photos={items.map((item) => ({...item.image.asset.fixed, alt: item.altText}))}
          targetRowHeight={typeof window !== 'undefined' ? (window.innerWidth * 0.25) : 300}
          renderImage={imageRenderer}
        />
      </GalleryWrapper>
      <AnimatePresence initial={false}>
        {viewerIsOpen && (
          <LightboxWrapper
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              y: { type: 'spring', stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 },
            }}
          >
            <Lightbox
              {...items[currentImage]}
              direction={direction}
              page={currentImage}
              onClose={closeLightbox}
              onNext={handleNext}
              onPrev={handlePrev}
            />
          </LightboxWrapper>
        )}
      </AnimatePresence>
    </>
  );
}

export default MediaGallery;
