import UnstyledImage from 'gatsby-image';
import styled from 'styled-components';

import colors from '@utils/colors';
import { weights } from '@utils/fonts';
import { H_PADDING_MOBILE } from '@utils/space';
import { Body as UnstyledBody, Heading as UnstyledHeading } from '@components/type';

export const IconButton = styled.button`
  flex: 1 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 6px;
  appearance: none;
  outline: 0;
  cursor: pointer;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${H_PADDING_MOBILE};
`;

export const Body = styled(UnstyledBody)`
  color: ${colors.N600};
`;

export const Content = styled.div`
  flex: 1 0 auto;
`;

export const Credit = styled.div`
  margin-top: 16px;
  font-weight: ${weights.BOLD};
  font-family: ${props => props.theme.fonts.SANS};
  font-size: 12px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & ${IconButton} {
    flex: 1;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${H_PADDING_MOBILE};

  & ${IconButton} > svg {
    margin-left: 16px;
  }
`;

export const Heading = styled(UnstyledHeading)`
  flex: 1 1 auto;
  margin-bottom: 8px;
`;

export const Image = styled(UnstyledImage)`
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 66.67%;
  max-height: 66.67%;
  width: 100%;
`;

export const Info = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: ${H_PADDING_MOBILE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: ${colors.N0};
  transform: translateY(${(props) => props.open ? '0' : '100%'});
  opacity: ${(props) => props.open ? 1 : 0};
  transition: opacity 150ms linear, transform 200ms ease;

  ${Header} {
    padding: 0;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Parent = styled(UnstyledHeading)`
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 3px solid ${colors.B100};
`;

export const ParentWrapper = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.N0};
`;
