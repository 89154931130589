import React from 'react';
import styled from 'styled-components';

import { mq } from '@utils/responsive';
import Desktop from './desktop';
import Mobile from './mobile';

const Wrapper = styled.div`
  & > div:first-child {
    display: block;
  }

  & > div:last-child {
    display: none;
  }

  ${mq.gtsm`
    & > div:first-child {
      display: none;
    }

    & > div:last-child {
      display: block;
    }
  `}
`;

function Lightbox(props) {
  return (
    <Wrapper>
      <div>
        <Mobile {...props} />
      </div>
      <div>
        <Desktop {...props} />
      </div>
    </Wrapper>
  );
}

export default Lightbox;
