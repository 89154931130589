import UnstyledImage from 'gatsby-image';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { weights } from '@utils/fonts';
import { Body as UnstyledBody, Heading as UnstyledHeading } from '@components/type';

export const AnimateWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Credit = styled.div`
  margin-top: 16px;
  font-weight: ${weights.BOLD};
  font-family: ${props => props.theme.fonts.SANS};
  font-size: 12px;
`;

export const Next = styled.div`
  position: absolute;
  right: -36px;
  z-index: 2;
`;

export const Prev = styled.div`
  position: absolute;
  left: -36px;
  z-index: 2;
`;

export const IconButton = styled.button`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 6px;
  appearance: none;
  outline: 0;
  cursor: pointer;
`;

export const Body = styled(UnstyledBody)`
  color: ${colors.N600};
`;

export const Content = styled.div`
  flex: 1 0 auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  & ${IconButton} {
    display: flex;
    margin-right: -8px;
    margin-left: 40px;
  }
`;

export const Heading = styled(UnstyledHeading)`
  flex: 1 0 auto;
  margin-bottom: 8px;
`;

export const Image = styled(UnstyledImage)`
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  max-height: 100%;
  width: ${9 / 12 * 100}%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${(props) => props.hasTitle && css`
    padding-top: 16px;
    border-top: 3px solid ${colors.B100};
  `}
`;

export const InfoWrapper = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: ${3 / 12 * 100}%;
  padding: 0 64px 0 32px;
  background-color: ${colors.N0};
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
`;

export const Parent = styled(UnstyledHeading)`
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 3px solid ${colors.B100};
`;

export const ParentWrapper = styled.div`
  flex: 0;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  padding: 64px;
  padding-right: 0;
  background-color: ${colors.N0};
`;
